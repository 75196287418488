import React from "react"
import Navbar from "./../../components/Navbar/Navbar"
import "./index.css"
import { FaLongArrowAltRight } from "react-icons/fa"
import { navigate } from "gatsby"
import {
  Accordion,
  Card,
  Alert,
  Container,
  Row,
  Col,
  Spinner,
} from "react-bootstrap"
import { FcCancel, FcPlanner, FcOk } from "react-icons/fc"
import { GoFileSubmodule } from "react-icons/go"
import { AiOutlineDownload } from "react-icons/ai"
import { RiUploadCloudFill } from "react-icons/ri"
import { BsCheckCircle } from "react-icons/bs"
import { GrFormDown } from "react-icons/gr"
import EasypasiaLogo from "./../../images/easypaisa.png"
import Jazzcashlogo from "./../../images/jazzcashlogo.png"
import UBLLogo from "./../../images/ubl-united-bank-limited-logo.png"
import axios from "axios"
import { saveAs } from "file-saver"
import FirebaseApp from "./../../config/firebase"
let database = FirebaseApp.database().ref("/")
var moment = require("moment-timezone")

export default class CourseInstruction extends React.Component {
  constructor() {
    super()
    this.state = {
      suspended: "release",
      todayDate: "",
      Disabled: true,
      monthsData: [],
      monthlyFeesSec: false,
      isLoader: false,
      serialNumber: 0,
      uploadLoader: false,
      url: "",
      completedMonths: [],
      bodyLoader: true,
      isCompleted: "inProgress",
      completedCourse: [],
      _image_type_error: "",
      shedule: [],
      launchClasses: false,
      batches: [],
      // user:
    }
  }
  componentDidMount = async () => {
    let { completedMonths, monthsData, completedCourse, batches } = this.state
    var todaysDateFormat = moment().format("DD/MM/YY")
    var currentDate = moment().tz("Pakistan/Islamabad").format("DD")
    var currMonthName = moment().tz("Pakistan/Islamabad").format("MMMM")
    var suspendedStudent
    var duration = []
    FirebaseApp.auth().onAuthStateChanged(async user => {
      if (user && user.emailVerified) {
        await database
          .child("Registration/" + user.uid + "/")
          .once("value", async res => {
            suspendedStudent = res.val()
            this.setState({ coure: res.val().course })
            if (suspendedStudent.completedCourse !== undefined) {
              this.setState({
                completedCourse: suspendedStudent.completedCourse,
              })
            }
            this.setState({
              user: suspendedStudent,
              bodyLoader: false,
              monthsData: suspendedStudent.duration,
              isCompleted: suspendedStudent.isCompleted,
            })
            if (suspendedStudent.suspend === "suspend") {
              this.setState({ suspended: "suspended" })
            } else if (
              suspendedStudent.suspend === undefined ||
              suspendedStudent.suspend === "release"
            ) {
              if (
                suspendedStudent.course !== "Freelancing" &&
                suspendedStudent.course !== "Youtube Mastery" &&
                suspendedStudent.course !== "Social Media Marketing" &&
                suspendedStudent.paytype !== "onetime"
              ) {
                const endOfMonth = moment().clone().endOf("month").format("DD")
                var minusOneEndOfMonth = endOfMonth - "1"
                if (
                  (currentDate === "25" &&
                    suspendedStudent.monthlyFeesStructure) ||
                  suspendedStudent.monthlyFeesStructure.monthlyPaid === false ||
                  suspendedStudent.monthlyFeesStructure.monthlyPaid ===
                    "Pending"
                ) {
                  this.setState({ monthlyFeesSec: true })
                } else if (
                  currMonthName !== suspendedStudent.monthlyFeesStructure.month
                ) {
                  database
                    .child(
                      "Registration/" +
                        this.state.user.uid +
                        "/" +
                        "monthlyFeesStructure"
                    )
                    .update({ monthlyPaid: false, month: currMonthName })
                }
                this.setState({ suspended: "release" })

                await database
                  .child("Registration/" + user.uid + "/" + "paidMonths/")
                  .once("value", async res => {
                    res.forEach(data => {
                      completedMonths.push(data.val())
                    })
                  })

                await database
                  .child("Registration/" + user.uid + "/" + "duration/")
                  .once("value", async res => {
                    res.forEach(data => {
                      duration.push(data.val())
                    })
                  })
                this.setState({ completedMonths: completedMonths })

                // console.log(
                //   "==============duration===========",
                //   duration.length
                // )
                // console.log(
                //   "==============completedMonths===========",
                //   completedMonths.length
                // )

                if (duration.length === completedMonths.length) {
                  var completedCourseObj = {
                    name: suspendedStudent.name,
                    fatherName: suspendedStudent.fatherName,
                    course: suspendedStudent.course,
                    email: suspendedStudent.email,
                    nicNumber: suspendedStudent.nicNumber,
                    mobileNum: suspendedStudent.mobileNum,
                    url: suspendedStudent.url,
                  }
                  let completedCourse = this.state.completedCourse
                  completedCourse.push(completedCourseObj)
                  if (this.state.isCompleted !== "completed") {
                    this.setState({ isCompleted: "completed" })
                    await database
                      .child("Registration/" + user.uid + "/")
                      .update({ isCompleted: "completed" })
                    await database
                      .child(
                        "Registration/" + user.uid + "/" + "completedCourse"
                      )
                      .set(completedCourse)
                    completedCourseObj.PassedCourseDate = moment()
                      .tz("Pakistan/Islamabad")
                      .format("L")
                    await database
                      .child("PassesStudents/")
                      .push(completedCourseObj)
                    navigate("/applyfornewcourse")
                  }
                }
              } else {
                // alert("onetime true");
                if (todaysDateFormat === suspendedStudent.feesPaidDate) {
                  var completedCourseObj = {
                    name: suspendedStudent.name,
                    fatherName: suspendedStudent.fatherName,
                    course: suspendedStudent.course,
                    email: suspendedStudent.email,
                    nicNumber: suspendedStudent.nicNumber,
                    mobileNum: suspendedStudent.mobileNum,
                    url: suspendedStudent.url,
                  }
                  let completedCourse = this.state.completedCourse
                  completedCourse.push(completedCourseObj)
                  if (this.state.isCompleted !== "completed") {
                    this.setState({ isCompleted: "completed" })
                    await database
                      .child("Registration/" + user.uid + "/")
                      .update({ isCompleted: "completed" })
                    await database
                      .child(
                        "Registration/" + user.uid + "/" + "completedCourse"
                      )
                      .set(completedCourse)
                    completedCourseObj.PassedCourseDate = moment()
                      .tz("Pakistan/Islamabad")
                      .format("L")
                    await database
                      .child("PassesStudents/")
                      .push(completedCourseObj)
                    navigate("/applyfornewcourse")
                  }
                }
              }
            }
          })

        await database.child("LaunchClasses/").once("value", res => {
          this.setState({ launchClasses: res.val().launchClasses })
        })
      } else {
        navigate("/")
      }

      var today = new Date()
      var dd = today.getDate()
      var mm = today.getMonth() + 1
      var yyyy = today.getFullYear()
      this.setState({ todayDate: dd + "/" + mm + "/" + yyyy })
      var currentShedule = []
      database
        .child("Shedule/" + this.state.user.course + "/")
        .on("child_added", shedule => {
          let d = shedule.val()
          let curentDate = moment()
            .tz("Pakistan/Islamabad")
            .format("YYYY-MM-DD")
          if (
            curentDate &&
            curentDate >= d.startDate &&
            currentDate <= d.endDate
          ) {
            currentShedule.push(shedule.val())
            this.setState({ shedule: currentShedule })
          }
        })
      database.child("batches/").on("child_added", res => {
        var data = res.val()
        data.id = res.key
        if (suspendedStudent.batch === data.batch_name) {
          this.setState({ batches: data })
        }
      })
    })
  }

  createAndDownloadPdf = async () => {
    this.setState({ isLoader: true })
    let { user, serialNumber } = this.state

    await database.child("SerialNumber/").once("value", res => {
      var snumber = res.val()
      serialNumber = snumber.serialNumber
      this.setState({ serialNumber: snumber.serialNumber })
    })
    let obj = {
      name: user.name,
      fname: user.fatherName,
      nic: user.nicNumber,
      course: user.course,
      sNumber: serialNumber,
      fees: user.monthlyFees,
      amountInWords: "Three Thousand Only/=",
    }

    axios
      .post("https://chalangenerator.herokuapp.com/createchalan", obj)
      .then(() =>
        axios.get("https://chalangenerator.herokuapp.com/chalan-pdf", {
          responseType: "blob",
        })
      )
      .then(res => {
        const pdfBlob = new Blob([res.data], { type: "application/pdf" })
        saveAs(pdfBlob, "iSkillersChalan.pdf")
        this.setState({ isLoader: false })
      })
    database
      .child("SerialNumber/")
      .set({ serialNumber: this.state.serialNumber + 1 })
  }

  getImageURL = async e => {
    if (e.target.files.length !== 0) {
      var currMonthName = moment().tz("Pakistan/Islamabad").format("MMMM")
      let imageName = e.target.files[0].name
      var imageType = e.target.files[0].type
      if (imageType === "image/png" || imageType === "image/jpeg") {
        this.setState({ uploadLoader: true, imagepath: e.target.files[0].name })
        let ref = FirebaseApp.storage()
          .ref("/")
          .child(`monthlyChalan/${currMonthName + this.state.user.uid}`)
        await ref.put(e.target.files[0])
        ref.getDownloadURL().then(url => {
          this.setState({
            url: url,
          })
        })
      } else {
        this.setState({
          _image_type_error: "Please select file in jpeg or png format...",
        })
      }
    } else {
      this.setState({ _image_type_error: "" })
    }
  }

  uploadChalan = () => {
    var currMonthName = moment().tz("Pakistan/Islamabad").format("MMMM")
    database
      .child(
        "Registration/" + this.state.user.uid + "/" + "monthlyFeesStructure"
      )
      .update({
        PaidChalan: this.state.url,
        monthlyPaid: "Pending",
        month: currMonthName,
      })
    window.location.reload(false)
  }
  downloadCertificate = () => {

let {user } = this.state

      let obj = {
        name: user.name,
        fname: user.fatherName,
        certificateNum: 2765782,
        issueDate: 10,
      }
  
      axios
        .post("http://localhost:5000/createcertificate", obj)
        .then(() =>
          axios.get("http://localhost:5000/getcertificate", {
            responseType: "blob",
          })
        )
        .then(res => {
          const pdfBlob = new Blob([res.data], { type: "application/pdf" })
          saveAs(pdfBlob, "Certificate.pdf")
          this.setState({ isLoader: false })
        })

  }

  render() {
    let {
      monthsData,
      user,
      url,
      completedMonths,
      isCompleted,
      _image_type_error,
      shedule,
      launchClasses,
      batches,
    } = this.state

    return (
      <div className="_main_container">
        <Navbar />
        {isCompleted && isCompleted === "inProgress" ? (
          <div className="_body_view">
            <div className="_card_heading">My Admit Card:</div>
            <div style={{ paddingLeft: "3%" }}>
              <FaLongArrowAltRight
                style={{ fontWeight: "bold", color: "#98189a", fontSize: 30 }}
              />
              <button
                className="_card_button"
                onClick={() => navigate("/AdmitCard")}
              >
                click here
              </button>{" "}
              <span>to view your admit card</span>
            </div>
            {batches.classes === false ? (
              <div style={{ textAlign: "center", padding: "20px" }}>
                Your Classes will be start soon..
              </div>
            ) : (
              <div>
                {user !== undefined &&
                user.course !== "Freelancing" &&
                user.course !== "Youtube Mastery" &&
                user.course !== "Social Media Marketing" &&
                user !== undefined &&
                user.paytype !== "onetime" ? (
                  <Container>
                    <Row
                      className="justify-content-center"
                      style={{ display: "flex", textAlign: "center" }}
                    >
                      {monthsData &&
                        monthsData.map((v, i) => {
                          return (
                            <Col lg="2" xs="2">
                              <div
                                className="_monthDiv"
                                disabled={v.Disabled}
                                style={
                                  i + 1 <= completedMonths.length
                                    ? { opacity: 1 }
                                    : { opacity: 0.5 }
                                }
                              >
                                {i + 1 <= completedMonths.length ? (
                                  <FcOk className="_monthIcon" />
                                ) : (
                                  <FcPlanner className="_monthIcon" />
                                )}
                                <div>Phase {i + 1}</div>
                              </div>
                            </Col>
                          )
                        })}
                    </Row>
                  </Container>
                ) : null}
                <div>
                  {this.state.bodyLoader ? (
                    <div className="_body_loader">
                      <Spinner
                        as="span"
                        animation="grow"
                        size="lg"
                        role="status"
                        aria-hidden="true"
                        style={{ color: "purple" }}
                      />
                    </div>
                  ) : this.state.suspended === "release" ? (
                    <>
                      {this.state.monthlyFeesSec ? (
                        <div>
                          {user.monthlyFeesStructure &&
                          user.monthlyFeesStructure.monthlyPaid ===
                            "Pending" ? (
                            <>
                              <Container>
                                <Row className="justify-content-center">
                                  <Col lg="10" xs="12">
                                    <img
                                      src={require("./../../images/pending.jpg")}
                                      style={{
                                        width: 150,
                                        height: 100,
                                        marginTop: 20,
                                      }}
                                    />
                                    <div className="pending_div">
                                      <div className="pending_txt">
                                        Please wait admin will approve you very
                                        soon Stay connected
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                              </Container>
                            </>
                          ) : (
                            <>
                              {/* <<<<<<<<<<<<<<<<<<<<<<<< MONTHLY CHALAN SECTION END >>>>>>>>>>>>>>>>>>>> */}
                              <div style={{ marginTop: 20 }}>
                                <Container fluid>
                                  <Row className="justify-content-center">
                                    <Col lg="10">
                                      <Accordion
                                        style={{ margin: "5px" }}
                                        defaultActiveKey="1"
                                      >
                                        <Card>
                                          <Card.Header className="_accordionToggle">
                                            <Accordion.Toggle
                                              variant="link"
                                              eventKey="1"
                                              className="_accordionToggleDiv"
                                            >
                                              <div className="_courseTitle">
                                                <span
                                                  style={{ marginLeft: "10px" }}
                                                >
                                                  {user.course}
                                                </span>
                                              </div>
                                              <div>
                                                <GrFormDown
                                                  size="30"
                                                  color="grey"
                                                />
                                              </div>
                                            </Accordion.Toggle>
                                          </Card.Header>
                                          <Accordion.Collapse eventKey="1">
                                            <Card.Body>
                                              <div>
                                                <div>
                                                  <table className="_course_table_main">
                                                    <thead className="course_table_thead">
                                                      <tr className="_course_table_tr">
                                                        <th
                                                          className="_course_table_th_td  _course_table_th"
                                                          scope="col"
                                                        >
                                                          Applicant Name
                                                        </th>
                                                        <th
                                                          className="_course_table_th_td _course_table_th"
                                                          scope="col"
                                                        >
                                                          Father's Name
                                                        </th>
                                                        <th
                                                          className="_course_table_th_td _course_table_th"
                                                          scope="col"
                                                        >
                                                          CNIC/B-Form
                                                        </th>
                                                        <th
                                                          className="_course_table_th_td _course_table_th"
                                                          scope="col"
                                                        >
                                                          Course
                                                        </th>
                                                        <th
                                                          className="_course_table_th_td _course_table_th"
                                                          scope="col"
                                                        >
                                                          Amount/Rs
                                                        </th>
                                                      </tr>
                                                    </thead>
                                                    <tbody className="course_table_thead">
                                                      <tr className="_course_table_tr">
                                                        <td
                                                          className="_course_table_th_td _course_table_td"
                                                          data-label="Applicant Name"
                                                        >
                                                          {user.name}
                                                        </td>
                                                        <td
                                                          className="_course_table_th_td _course_table_td"
                                                          data-label="Father's Name"
                                                        >
                                                          {user.fatherName}
                                                        </td>
                                                        <td
                                                          className="_course_table_th_td _course_table_td"
                                                          data-label="CNIC/B-Form"
                                                        >
                                                          {user.nicNumber}
                                                        </td>
                                                        <td
                                                          className="_course_table_th_td _course_table_td"
                                                          data-label="Course"
                                                        >
                                                          {user.course}
                                                        </td>
                                                        <td
                                                          className="_course_table_th_td _course_table_td course_amount"
                                                          data-label="Amount/Rs"
                                                        >
                                                          {user.monthlyFees}
                                                        </td>
                                                      </tr>
                                                    </tbody>
                                                  </table>
                                                </div>
                                                <div
                                                  style={{
                                                    fontSize: 12,
                                                    paddingTop: 20,
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  Pay with given UBL Bank
                                                  account.
                                                </div>
                                                <div style={{ marginTop: 20 }}>
                                                  <Row className="justify-content-center">
                                                    <Col lg="3">
                                                      <div
                                                        style={{
                                                          textAlign: "center",
                                                        }}
                                                      >
                                                        <div
                                                          style={{
                                                            color: "grey",
                                                          }}
                                                        >
                                                          270466645
                                                        </div>
                                                        <img
                                                          src={UBLLogo}
                                                          width="100px"
                                                        />
                                                        <div
                                                          style={{
                                                            fontSize: 12,
                                                            fontWeight:
                                                              "normal",
                                                          }}
                                                        >
                                                          Branch Code: 1941
                                                        </div>
                                                      </div>
                                                    </Col>
                                                  </Row>
                                                </div>
                                              </div>
                                            </Card.Body>
                                          </Accordion.Collapse>
                                        </Card>
                                      </Accordion>
                                    </Col>
                                  </Row>
                                </Container>
                              </div>
                              <>
                                <div className="_paid_chalan">
                                  Upload your monthly paid chalan (iHunar
                                  copy)
                                </div>
                                <div className="_uploadChalanDiv">
                                  <>
                                    <label className="custom-file-upload">
                                      {url === "" ? (
                                        <>
                                          {this.state.uploadLoader ? (
                                            <Spinner
                                              as="span"
                                              animation="border"
                                              size="sm"
                                              role="status"
                                              style={{ color: "purple" }}
                                              aria-hidden="true"
                                            />
                                          ) : (
                                            <>
                                              <input
                                                type="file"
                                                onChange={this.getImageURL}
                                              />
                                              <RiUploadCloudFill
                                                size="20"
                                                style={{ marginRight: 5 }}
                                              />
                                              <span>
                                                <div
                                                  style={{
                                                    fontSize: 12,
                                                    paddingBottom: 20,
                                                  }}
                                                >
                                                  Select photo of the paid
                                                  chalan (office) copy or
                                                  easypaisa,jazzcash reception
                                                </div>
                                              </span>
                                            </>
                                          )}
                                        </>
                                      ) : null}
                                      {url ? (
                                        <>
                                          <BsCheckCircle
                                            style={{
                                              color: "green",
                                              fontSize: 30,
                                            }}
                                          />
                                          <div
                                            style={{
                                              textAlign: "center",
                                              fontSize: "10px",
                                            }}
                                          >
                                            {this.state.imagepath}
                                          </div>
                                        </>
                                      ) : null}
                                    </label>
                                  </>
                                  <div className="_image_type_error">
                                    {_image_type_error}
                                  </div>
                                  <p
                                    style={{
                                      textAlign: "right",
                                      width: "45%",
                                      display: "flex",
                                      flexDirection: "row",
                                      justifyContent: "flex-end",
                                      marginTop: 10,
                                    }}
                                  >
                                    <button
                                      className="_download_btn"
                                      disabled={url !== "" ? false : true}
                                      onClick={this.uploadChalan}
                                    >
                                      {" "}
                                      <RiUploadCloudFill
                                        size="20"
                                        style={{ marginRight: 5 }}
                                      />
                                      <div>send chalan</div>
                                    </button>
                                  </p>
                                </div>
                              </>
                              {/* <<<<<<<<<<<<<<<<<<<<<<<< MONTHLY CHALAN SECTION END >>>>>>>>>>>>>>>>>>>> */}
                            </>
                          )}
                        </div>
                      ) : (
                        <div>
                          {/* <<<<<<<<<<<<<<<<TEACHING PLAN CONTENT>>>>>>>>>> */}
                          <Container fluid>
                            <Row className="justify-content-center">
                              <Col lg="11" xs="12" sm="12">
                                {shedule.length !== 0 ? (
                                  shedule.map((val, i) => {
                                    return (
                                      <>
                                        <div className="_coursedDiv" key={i}>
                                          <div
                                            style={{
                                              display: "flex",
                                              flexDirection: "row",
                                              alignItems: "center",
                                              justifyContent: "space-between",
                                            }}
                                          >
                                            <div
                                              style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                              }}
                                            >
                                              <GoFileSubmodule
                                                size="30"
                                                color="#00af80"
                                              />
                                              <div className="_planTitle">
                                                {" "}
                                                {val.title}
                                              </div>
                                            </div>
                                            <div className="_planDate">
                                              {val.startDate +
                                                "-to-" +
                                                val.endDate}
                                            </div>
                                          </div>

                                          <div className="_courseplandTxt">
                                            {val.subTitleArray ? (
                                              <div>
                                                <ul
                                                  style={{ marginTop: "10px" }}
                                                >
                                                  {val.subTitleArray.map(
                                                    (d, j) => {
                                                      return (
                                                        <li key={j}>
                                                          {d.subTitle}
                                                        </li>
                                                      )
                                                    }
                                                  )}
                                                </ul>
                                              </div>
                                            ) : null}
                                          </div>
                                          <p
                                            style={{
                                              fontSize: 12,
                                              marginTop: 10,
                                            }}
                                          >
                                            {val.description}
                                          </p>
                                        </div>
                                      </>
                                    )
                                  })
                                ) : (
                                  <div style={{ fontSize: 12, marginTop: 20 }}>
                                    currently no any shedule available
                                  </div>
                                )}
                              </Col>
                            </Row>
                          </Container>
                          {/* <<<<<<<<<<<<<<<<END TEACHING PLAN>>>>>>>>>> */}
                        </div>
                      )}
                    </>
                  ) : (
                    <div style={{ marginTop: "60px" }}>
                      {/* <<<<<<<<<<<<<<<< SUSPENDED CONTENT >>>>>>>>>> */}
                      <Container>
                        <Row className="justify-content-center">
                          <Col lg="12">
                            <div className="_suspendedDiv">
                              <FcCancel size="30" />
                              <div className="_suspenedTxt">
                                You are temprary suspended due to some
                                reasons....
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Container>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        ) : (
          // <<<<<<<<<<<<<<<<<<<<<<<< AFTER COMPLETED COURSE >>>>>>>>>>>>>>>>>>>>>>>>>>>>
          <div style={{ textAlign: "center", padding: "40px", color: "green" }}>
            Congratulation You completed your course successfullly
            <br />
            <br />
            <br />
            {user.registrationType === "online" ? (
              // <a href="Certificate.pdf" download="Certificate.pdf">
                <button
                  className="_certificate_button"
                  onClick={()=> this.downloadCertificate()}
                >
                  Download here passed Certificate
                </button>
              // </a>
            ) : null}
          </div>
        )}
      </div>
    )
  }
}
